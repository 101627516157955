const ApiCrm      = 'https://pdtclientsolutions.com/ventascc/api'
const ApiWhatsapp = 'http://62.171.182.68:3001'


// const ApiCrm      = 'https://pdtclientsolutions.com/ventascc/api'
// const ApiWhatsapp = 'http://127.0.0.1:3001'



const base_url = function base_url(server, uri){
    return `${server}/${uri}`
}


export  {
    base_url,
    ApiCrm,
    ApiWhatsapp
}